<template>
    <div class="slide-wrapper">
        <Swiper class="slider" :pagination="pagination" :slides-per-view="slidesPerView" :space-between="spaceBetween">
            <slot />
        </Swiper>
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
    import SwiperCore, { Pagination } from 'swiper';
    import { Swiper } from 'swiper/vue/swiper';
    import 'swiper/modules/pagination/pagination.min.css';
    import 'swiper/swiper.min.css';

    SwiperCore.use([Pagination]);

    export default {
        name: 'Slider',
        components: {
            Swiper,
        },
        props: {
            slidesPerView: {
                type: Number,
                default: 1,
            },
            spaceBetween: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                pagination: {
                    clickable: true,
                },
            };
        },
    };
</script>
